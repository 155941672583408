export class RetornoChecklist {
    titulo: string;
    secoes: Array<ChecklistConfiguracao> = new Array<ChecklistConfiguracao>();
    arquivos: Array<string> = new Array<string>();
}

export class ChecklistConfiguracao {
    nome: string;
    configuracoes: Array<Configuracao> = new Array<Configuracao>();
}

export class Configuracao {
    id: number;
    pergunta: Pergunta = new Pergunta();
    comparador: Comparador = new Comparador();
    resposta: any;
    tipo_resposta: TipoResposta = new TipoResposta();
    is_obrigatorio: boolean;
}

export class Pergunta {
    id: number;
    titulo: string;
}

export class Comparador {
    id: number;
    valor: string;
}

export class TipoResposta {
    id: number;
    descricao: string;
    is_captura_imagem: boolean;
    id_tipagem_tipo_resposta: number;
    opcoes_resposta: Array<OpcaoResposta> = new Array<OpcaoResposta>();
    is_ativo: boolean;
    is_aplicado: boolean;
}

export class OpcaoResposta {
    id: number;
    descricao: string;
}

export class PreChecklist {
    data_alteracao: Date;
    id: number;
    id_pergunta: number;
    id_tipo_resposta: number;
    is_ativo: boolean;
    is_obrigatorio: boolean;
    opcoes_resposta: Array<OpcaoResposta> = new Array<OpcaoResposta>();
    secao: string;
    tamanho_maximo: number;
    titulo_pergunta: string;
}

export class NotificacaoEmail {
    id_notificacao_email: number;
    titulo_notificacao_email: string;
}
