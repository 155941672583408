export enum EnumTipoResposta {
    SIM_NAO = 1,
    OK_NOK = 2,
    TEXTO_LIVRE = 3,
    VALOR_NUMERICO = 4,
    DATA = 5,
    HORA = 6,
    DATA_AUTOMATICA = 7,
    HORA_AUTOMATICA = 8,
    ASSINATURA = 9
}
